/* eslint-disable react/prop-types */
import { LoadingOutlined, SnippetsOutlined } from '@ant-design/icons'
import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from '../../../../../src/config'
import { RegeneratedScriptToCanvas } from '../../../../../src/shared/utils/VideoGeneration/RegenerateScriptToCanvas'
import { Button, Modal } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../context/globalContext'
import { RegeneratedScriptModal } from './regeneratedScriptModal'
import { ScriptContainer } from './scriptContainer'
import { RegeneratedScriptToCanvasNew } from '../../../../../src/shared/utils/VideoGeneration/RegerateScriptDetailsNew'
import { getCookie } from 'cookies-next'
import { decodeAndParseCookie } from '../../../../../src/App'
import { RandomScriptToCanvas } from '../../../../../src/shared/utils/VideoGeneration/RandomScriptToCanvas'
import { ScriptToCanvasBurst } from '../../../../../src/shared/utils/VideoGeneration/BurstScriptToCanvas'
import { resizeDesign } from '../../../../../src/shared/utils/polotno/resizing'
import ModalTabs from './modalTabs/modalTabs'
import { PromptScriptToCanvas } from '../../../../../src/shared/utils/VideoGeneration/PromptScriptToCanvas'
import { compareJsonAndReturnUpdated } from '../../../../../src/shared/utils/compareScriptdata'
import { NewContextScriptToCanvas } from '../../../../../src/shared/utils/VideoGeneration/newContextScriptToCanvas'
import { ContextRegeneratePromptScriptToCanvas } from '../../../../../src/shared/utils/VideoGeneration/newContextPromptScriptTocanvas'
import { ScriptToCanvasBurstRegen } from '@src/shared/utils/VideoGeneration/burstRegenerations/BurstScriptToCanvasRegen'

interface ScriptButtonProps {
  store: any
  open: boolean
  setOpen: any
  setProgressBarPercentage: any
  defaultActivetab:any
  buttonName: any
}

export const ScriptButton: React.FC<ScriptButtonProps> = ({ store, setProgressBarPercentage, defaultActivetab, buttonName }) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UserDetail: [userDetails, setUserDetails],
    BackgroundMusic: [, setBackgroundMusic],
    IsLoading: [isLoading, setIsLoading],
    VideoStyle: [videoStyle],
    VideoMediaType: [videoMediaType],
    ProgressPercent: [, setProgressBarPercent],
    Story:[editedStory, setEditedStory]

  } = useGlobalContext()

  // use State to handle the states

  const [open, setOpen] = useState(false)

  const [updatedScriptDetails, setUpdatedScriptDetails] = useState<any>(scriptDetails)

  const [regeneratedScriptDetails, setRegeneratedScriptDetails] = useState<any>(scriptDetails ? scriptDetails.data : '')

  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)

  const [regnerateLoading, setRegenerateLoading] = useState<boolean>(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [isRegenerateButton, setIsRegenerateButton] = useState<boolean>(true)

  const [newScript, setNewScript] = useState()

  const [openNewScriptModal, setOpenNewScriptModal] = useState(false)
  
  const [newSceneAdded, setNewSceneAdded] = useState(false)

  const [storyChanged, setStoryChanged] = useState(false)
  const [tabKey, setTabKey]=useState(1)
  const [newContext, setNewContext] = useState("")
  const [isNewContextGenerationLoading, setIsNewContextGenerationLoading] = useState(false)
  // this useEffect is added to make sure that the save button is only enabled if there's a change made in the script
  useEffect(() => {
    if (scriptDetails && regeneratedScriptDetails) {
        
      const regeneratedItems = regeneratedScriptDetails?.filter((item: { isUpdated: any }) => item?.isUpdated)
      setIsRegenerateButton(regeneratedItems.length === 0)
    }
  }, [regeneratedScriptDetails])

  const showModal = () => {
    setOpen(true)
  }

  // this will show the new script that is made if the user regenerates a new script
  const showNewScriptModal = () => {
    setOpen(false)
    setOpenNewScriptModal(true)
  }

  // this api call is made when the user wants to regenerate a new script
  const handleRegenerateScript = async () => {
    // we pass the current script to our backend , and using that script we regenerate a new script.
    //In the future we can add the brand description in the body, and pass it to the backend so it has more context, would need to update the backend likewise
    const previousScript = [...scriptDetails.data]

    const postBody = {
      previousScript: previousScript,
    }

    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    try {
      setRegenerateLoading(true)
      let response

      // depending on the video style the different endpoint is hit
      if (videoStyle === 'Simple') {
        response = await localEndPoint.post('/api/v1/openAi/regenerateSimpleScript', postBody)
      }
      if (videoStyle === 'Burst') {
        response = await localEndPoint.post('/api/v1/openAi/regenerateBurstScript', postBody)
      }

      // adding the response accordingly
      if (response?.data) {
        setNewScript(response.data)
        setRegenerateLoading(false)
        setOpenNewScriptModal(true)
        setOpen(false)
      }
    } catch (error) {
      console.error('Failed to regenerate script:', error)
    }
  }

  // If there is any changes made in the current script, and the user wants to save those chnages
  const handleOk = async () => {
    console.log(store.toJSON())
    interface existingMediaType{
      voiceOver:string;
      src: string;
      type: string;
      gettyId: any;
      scriptIndex:number;
      burstVoiceOver:string;
      subIndex:number;
    }
    const existingMedia:existingMediaType[]=[]

    if(tabKey == 1){
        const previousScript = [...scriptDetails.data]
        const oldContext = scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' ')
        // const postBody = {
        //   olStory:oldContext,
        //   NewStory: newContext,
        //   json:previousScript
        //   // language: userDetails?.purpose?.language,
        //   // duration: userDetails?.purpose?.video_duration
        // }
        const postBody = {
          context: newContext,
          language: userDetails?.purpose?.language,
          duration: userDetails?.purpose?.video_duration
        }
      
        const localEndPoint = axios.create({
          baseURL: `${NODE_BACKEND_URL}`,
        })
        setIsNewContextGenerationLoading(true)
        // const response = await localEndPoint.post('/api/v1/openAi/context-script-new', postBody)
        let response 
        if(videoMediaType === 'AiImages' || videoMediaType === 'Dalle'){
          response = await localEndPoint.post('/api/v1/openAi/regenerateContexttScript-ai-image', postBody)

        }else{
          if(videoStyle === 'Burst'){
            response = await localEndPoint.post('/api/v1/openAi/regenerateContexttScript-burst', postBody)
          }else{
            response = await localEndPoint.post('/api/v1/openAi/regenerateContexttScript', postBody)
          }
          

        }
        setIsNewContextGenerationLoading(false)
        
        if(typeof(response.data)==="string"){
          handleOk()
          return
        }
        else{
          const newScript = compareJsonAndReturnUpdated(scriptDetails, response.data)
          store.pages.map((page:any)=>{
            // console.log(page.custom.captions[0].text)
            page.children.map((child:any)=>{
              console.log(child.id)
              if(child.id.includes('quickadsLogo') || child.id.includes('cta')){ 
                console.log()
              }else{
                if(child.type === 'video' || child.type === 'image'){
                  existingMedia.push({voiceOver:page.custom.captions[0].text, src:child.src, type:child.type, gettyId:child.custom?.gettyId || undefined, scriptIndex:page.custom.scriptIndex, burstVoiceOver:child.custom?.voiceOver, subIndex: child.custom?.subIndex})
                }
              }
            })
          })
          // const uniqueArray: existingMediaType[] = Array.from(
          //   new Set(existingMedia.map((obj) => JSON.stringify(obj)))
          // ).map((str) => JSON.parse(str));
          // console.log(existingMedia)
          // if(newScript){
          //   return
          // }
          setIsLoading(true)
          setScriptDetails(newScript)
          if (IS_FREE_PLATFORM === 'true') {
            const script = getCookie('scriptData')
            const size = getCookie('projectSize')
            const industry = getCookie('projectIndustry')
      
            const data = decodeAndParseCookie(script)
            localStorage.setItem('scriptData', JSON.stringify(data))
            if (videoStyle === 'Simple') {
              await NewContextScriptToCanvas(
                store,
                newScript,
                industry ? industry.toString() : 'Fashion',
                setBackgroundMusic,
                setScriptDetails,
                setProgressBarPercent,
                '',
                userDetails.purpose.size,
                videoMediaType,
                userDetails?.colors?.secondary || '',
                existingMedia
              ).then(() => {
                const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
      
                resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
                store.openSidePanel("none");
              })
              setIsLoading(false)
            }
            if (videoStyle === 'Burst') {
              await ScriptToCanvasBurst(
                store,
                response.data,
                industry ? industry.toString() : 'Fashion',
                setBackgroundMusic,
                setScriptDetails,
                setProgressBarPercent,
                '',
                userDetails.purpose.size,
                videoStyle,
                videoMediaType,
                userDetails?.colors?.secondary || '',
                userDetails
              ).then(() => {
                const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
      
                resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
              })
              setIsLoading(false)
            }
          
          }  else {
            if(!userDetails.purpose?.promptToVideo){
              
              if(videoStyle === 'Burst'){
                setScriptDetails(response.data)
                await ScriptToCanvasBurstRegen(
                  store,
                  response.data,
                  userDetails.purpose.industry || 'Others',
                  setBackgroundMusic,
                  setScriptDetails,
                  setProgressBarPercent,
                  userDetails.logo,
                  userDetails.purpose.size,
                  videoStyle,
                  videoMediaType,
                  userDetails?.colors?.secondary || '',
                  userDetails,
                  existingMedia
                ).then(() => {
                  const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
          
                  resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size.toString() || '9:16')
                  store.openSidePanel("none");
                store.openSidePanel("video-timeline")

                })
                setIsLoading(false)
              }else{
                await NewContextScriptToCanvas(
                  store,
                  newScript,
                  userDetails.purpose.industry || 'Others',
                  setBackgroundMusic,
                  setScriptDetails,
                  setProgressBarPercent,
                  userDetails.logo,
                  userDetails.purpose.size,
                  videoMediaType,
                  userDetails?.colors?.secondary || '',
                  existingMedia
                ).then(() => {
                  const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
                  resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
                  store.openSidePanel("none");
                store.openSidePanel("video-timeline")

                })
              }
            }else{
              if(videoStyle === 'Burst'){
                setScriptDetails(response.data)
                await ScriptToCanvasBurstRegen(
                  store,
                  response.data,
                  userDetails.purpose.industry || 'Others',
                  setBackgroundMusic,
                  setScriptDetails,
                  setProgressBarPercent,
                  userDetails.logo,
                  userDetails.purpose.size,
                  videoStyle,
                  videoMediaType,
                  userDetails?.colors?.secondary || '',
                  userDetails,
                  existingMedia
                ).then(() => {
                  const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
          
                  resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size.toString() || '9:16')
                  store.openSidePanel("none");
                store.openSidePanel("video-timeline")

                })
                setIsLoading(false)
              }else{
                await ContextRegeneratePromptScriptToCanvas(
                  store,
                  newScript,
                  userDetails.purpose.industry || 'Others',
                  setBackgroundMusic,
                  setScriptDetails,
                  setProgressBarPercent,
                  userDetails.logo,
                  userDetails.purpose.size,
                  videoMediaType,
                  userDetails?.colors?.secondary || '',
                  userDetails,
                  existingMedia
                ).then(() => {
                  const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
                  resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
                  store.openSidePanel("none");
                store.openSidePanel("video-timeline")

                })
              }
            }
            // have to handle burstImage
            setEditedStory(newContext)
            setIsLoading(false)
          }
        }

    }else if(tabKey == 2){

    if(newSceneAdded){
        setIsLoading(true)
        setScriptDetails((prev:any)=>({
          ...prev,
          data: regeneratedScriptDetails
        }))
        const alternateScript={data: regeneratedScriptDetails}
        if (IS_FREE_PLATFORM === 'true') {
          const script = getCookie('scriptData')
          const size = getCookie('projectSize')
          const industry = getCookie('projectIndustry')
    
          const data = decodeAndParseCookie(script)
          localStorage.setItem('scriptData', JSON.stringify(data))
          if (videoStyle === 'Simple') {
            await RandomScriptToCanvas(
              store,
              alternateScript,
              industry ? industry.toString() : 'Fashion',
              setBackgroundMusic,
              setScriptDetails,
              setProgressBarPercent,
              '',
              userDetails.purpose.size,
              videoMediaType,
              userDetails?.colors?.secondary || '',
              userDetails
            ).then(() => {
              const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
    
              resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
              store.openSidePanel("none");
              store.openSidePanel("video-timeline")

            })
            setIsLoading(false)
          }
          if (videoStyle === 'Burst') {
            await ScriptToCanvasBurst(
              store,
              alternateScript,
              industry ? industry.toString() : 'Fashion',
              setBackgroundMusic,
              setScriptDetails,
              setProgressBarPercent,
              '',
              userDetails.purpose.size,
              videoStyle,
              videoMediaType,
              userDetails?.colors?.secondary || '',
              userDetails
            ).then(() => {
              const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
              resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
              store.openSidePanel("none");
              store.openSidePanel("video-timeline")

            })
            setIsLoading(false)
          }
        }  else {
          // have to handle burstImage
          if(!userDetails.purpose?.promptToVideo){
            if(videoStyle === 'Burst'){
              await ScriptToCanvasBurst(
                store,
                alternateScript,
                userDetails.purpose.industry || 'Others',
                setBackgroundMusic,
                setScriptDetails,
                setProgressBarPercent,
                userDetails.logo,
                userDetails.purpose.size,
                videoStyle,
                videoMediaType,
                userDetails?.colors?.secondary || '',
                userDetails
              ).then(() => {
                const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
        
                resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size.toString() || '9:16')
                store.openSidePanel("none");
                store.openSidePanel("video-timeline")
              })
              setIsLoading(false)
            }else{
              await RandomScriptToCanvas(
                store,
                alternateScript,
                userDetails.purpose.industry || 'Others',
                setBackgroundMusic,
                setScriptDetails,
                setProgressBarPercent,
                userDetails.logo,
                userDetails.purpose.size,
                videoMediaType,
                userDetails?.colors?.secondary || '',
                userDetails
              ).then(() => {
                const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
                resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
                store.openSidePanel("none");
                store.openSidePanel("video-timeline")

              })
            }
          }else{
            if(videoStyle === 'Burst'){
              await ScriptToCanvasBurst(
                store,
                alternateScript,
                userDetails.purpose.industry || 'Others',
                setBackgroundMusic,
                setScriptDetails,
                setProgressBarPercent,
                userDetails.logo,
                userDetails.purpose.size,
                videoStyle,
                videoMediaType,
                userDetails?.colors?.secondary || '',
                userDetails
              ).then(() => {
                const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
        
                resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size.toString() || '9:16')
                store.openSidePanel("none");
                store.openSidePanel("video-timeline")

              })
              setIsLoading(false)
            }else{
              await PromptScriptToCanvas(
                store,
                alternateScript,
                userDetails.purpose.industry || 'Others',
                setBackgroundMusic,
                setScriptDetails,
                setProgressBarPercent,
                userDetails.logo,
                userDetails.purpose.size,
                videoMediaType,
                userDetails?.colors?.secondary || '',
                userDetails
              ).then(() => {
                const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
                resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
                store.openSidePanel("none");
                store.openSidePanel("video-timeline")

              })
            }
          }
        
          setIsLoading(false)
        }
      }
  
  else{
    const regeneratedIndexes: number[] = []
    const regeneratedItems: { isUpdated: any }[] = []
  
    // the regenerated script has isUpdated object in its json, which tells us weather a change has been made in the script
    // we push the index of where the change is made in regeneratedIndexes
    // we push the entire script index where the change has been made into regeneratedItems
    regeneratedScriptDetails.forEach((item: { isUpdated: any }, index: number) => {
      if (item.isUpdated) {
        regeneratedItems.push(item)
        regeneratedIndexes.push(index)
      }
    })
  
    // we update the regeneretaed script into the scriptDetails, this is done so that the changes made here can be reflected throughout the project
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }
      return { ...updatedScript, data: regeneratedScriptDetails }
    })
  
    setIsLoading(true)
    // we call this function so that the changes can be applied to polotno and all the relevent information is added likewise
    await RegeneratedScriptToCanvas(
      store,
      regeneratedItems,
      regeneratedIndexes,
      setScriptDetails,
      userDetails?.purpose?.size,
      setProgressBarPercentage,
      videoMediaType
    )
  }
    }



    setIsLoading(false)
    setOpen(false)
  }

  // closes
  const handleCancel = async () => {
    setOpen(false)
    store.openSidePanel("video-timeline")
  }

  const [width, setWidth] = useState<number>(1000)
  const [height, setHeight] = useState<number>(600)
  useEffect(()=>{
    if(tabKey==1){
        setIsRegenerateButton(false)
        setStoryChanged(false)
    }else if(tabKey == 2){
        setIsRegenerateButton(false)
        setStoryChanged(true)
    }
  },[tabKey])
  // the script modal title, we have added the regenerate script button here
  const modalTitle = (
    <div style={{ display: 'flex', flexDirection: 'row', background: 'white' }}>
      <div
        style={{ width: '100%' }}
        className='draggable-modal-title' // This class will be used as a handle
      >
        Script
      </div>
      {/* <Button
        disabled={regnerateLoading}
        style={{
          background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
          borderColor: 'transparent',
          color: 'white',
          marginRight: '20px',
        }}
        icon={regnerateLoading ? <LoadingOutlined /> : null}
        onClick={() => {
          handleRegenerateScript()
        }}
      >
        Regenerate Script
      </Button> */}
      <Button
        disabled={isRegenerateButton || !storyChanged || confirmLoading || isNewContextGenerationLoading}
        style={{
          background: 'var(--gradient-primary)',
          borderColor: 'transparent',
          color: 'white',
          marginRight: '20px',
        }}
        icon={confirmLoading || isNewContextGenerationLoading ? <LoadingOutlined /> : null}
        onClick={() => {
          handleOk()
        }}
      >
        Save
      </Button>
    </div>
  )

  return (
    <>
      <Button
        onClick={() => {
          showModal()
          store.openSidePanel("video-timeline")
        }}
        style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', marginBottom: '10px', color: 'var(--color-primary-500)' }}
        icon={isGifRendering ? <LoadingOutlined /> : <SnippetsOutlined />}
      >
        {buttonName}
      </Button>
      
      <Modal
        title={modalTitle}
        width={1200}
        open={open}
        // okText={'Save'}
        // onOk={handleOk}
        // okButtonProps={{
        //   disabled: isRegenerateButton || !storyChanged,
        //   style: {
        //     background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
        //     borderColor: 'transparent',
        //     color: 'white',
        //     marginBottom: '10px',
        //   },
        // }}
        // confirmLoading={confirmLoading || isNewContextGenerationLoading}
        cancelText={'Close'}
        onCancel={handleCancel}
        footer={(_, {OkBtn, CancelBtn }) => (
          <>
            {/* depending the videoStyle the button is rendered */}
            {/* {videoStyle === 'Simple' || videoStyle === 'Burst' ? <OkBtn /> : null} */}
            {/* If new script is generated then this button is rendered */}
            {!regnerateLoading && newScript && (
              <Button
                onClick={async () => {
                  showNewScriptModal()
                  store.openSidePanel("video-timeline")
                }}
                style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', marginBottom: '10px', color: 'var(--color-primary-500)' }}
              >
                New Script
              </Button>
            )}
            <CancelBtn />
          </>
        )}
       
        // width={width} // Set the width of the Modal to match the GifPlayer's width
        // style={{
        //   top: '50%',
        //   transform: 'translateY(-50%)',
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   width:'800px'
        // }}
        destroyOnClose={true}
      >
        {/* The script container, which shows the script */}
        {/* <ScriptContainer
          store={store}
          setUpdatedScriptDetails={setUpdatedScriptDetails}
          setRegeneratedScriptDetails={setRegeneratedScriptDetails}
          setConfirmLoading={setConfirmLoading}
          setNewSceneAdded={setNewSceneAdded}
        /> */}
        <ModalTabs
         store={store}
         setUpdatedScriptDetails={setUpdatedScriptDetails}
         setRegeneratedScriptDetails={setRegeneratedScriptDetails}
         setConfirmLoading={setConfirmLoading}
         setNewSceneAdded={setNewSceneAdded}
         setStoryChanged={setStoryChanged}
         setTabKey={setTabKey}
         setNewContext={setNewContext}
         defaultActivetab={defaultActivetab}
        />
      </Modal>
      {/* This will make newScript button at the bottom depending on if a new script is generated */}
      {openNewScriptModal && (
        <RegeneratedScriptModal
          store={store}
          regeneratedScript={newScript}
          openNewScriptModal={openNewScriptModal}
          setOpenNewScriptModal={setOpenNewScriptModal}
          setProgressBarPercentage={setProgressBarPercentage}
        />
      )}
    </>
  )
}
