import { Alert } from 'antd';
import { QuickAdsLogoBlack } from '../../../src/assets'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const PageLayout = ({ children, heading, domainData }: { children: React.ReactNode; heading: string, domainData: any }) => {
  return (
    // <div
    //   style={{
    //     // overflow: 'auto',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '100vw',
    //     height: '100vh',
    //     margin: '0px',
    //   }}
    // >
    //   <div
    //     style={{
    //       display: 'flex',
    //       flexDirection: 'row',
    //       backgroundSize: 'cover',
    //       backgroundRepeat: 'no-repeat',
    //       borderTopRightRadius: '25px',
    //       paddingLeft: '30px',
    //       paddingTop: '20px',
    //       paddingBottom: '20px',
    //       alignItems: 'center',
    //       height: '7vh',
    //     }}
    //   >
    //     <ArrowLeftOutlined style={{ fontSize: '20px' }} onClick={() => window.history.back()} />
    //     <img src={domainData?.premium_domain_name?.includes('quickads') ? QuickAdsLogoBlack : domainData?.logo_url} width={domainData?.premium_domain_name?.includes('quickads')?110:''}   alt='Logo' style={{ marginLeft: '20px',maxHeight:'46px' }}></img>
    //     <Alert 
    //   message='Any watermark on the video will be removed in the download.' 
    //   type="info"
    //   style={{
    //     fontSize: '12px',
    //   }}/>
    //   </div>
    //   <div
    //     style={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       flex: 1,
    //       overflow: 'auto',
    //     }}
    //   >
    //     {children}
    //   </div>
    // </div>
    <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    margin: '0px',
  }}
>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderTopRightRadius: '25px',
      paddingLeft: '30px',
      paddingTop: '20px',
      paddingBottom: '20px',
      alignItems: 'center',
      height: '7vh',
      justifyContent: 'space-between', // This aligns the content with space between them
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ArrowLeftOutlined style={{ fontSize: '20px' }} onClick={() => window.history.back()} />
      <img
        src={
          domainData?.premium_domain_name?.includes('quickads')
            ? QuickAdsLogoBlack
            : domainData?.logo_url
        }
        width={domainData?.premium_domain_name?.includes('quickads') ? 110 : ''}
        alt="Logo"
        style={{ marginLeft: '20px', maxHeight: '46px' }}
      />
    </div>
    <Alert
      message="Any watermark on the video will be removed in the download."
      type="info"
      style={{
        fontSize: '12px',
        marginRight: '20px', // Added margin to the right for spacing from the border
      }}
    />
   <div>
    
   </div>
  </div>
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'auto',
    }}
  >
    {children}
  </div>
</div>

  )
}
