import { DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { useGetVoices } from '../../../api/elevenLabs/getVoices'
import { categorisedMusicList, whisperAiVoiceList } from '../../../data/music'
import { Button, InputNumberProps, Row, Select, Slider, SliderSingleProps, Switch, Tag, message } from 'antd'
import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../../../context/globalContext'
import { AiVoiceRow } from '../../video/customVideoTimeline/cardRows/aiVoiceRow'
import '../audioPanel.less'
import { generateUniqueId } from '../../../shared/utils/core'
import { aiVoicesResponse } from '../../../data/aiVoiceResponse'
import { formatDuration } from '../../../shared/utils/convertSecs'
import { prefetchAsset } from '../../../shared/utils/remotion'
import { IS_FREE_PLATFORM } from '../../../config'
import { getDomainID, getToken } from '../../../config/config'
import { aiTextToSpeech, generateCaptions, getPrevNextText } from '../../../shared/utils/VideoGeneration/audioGeneration'

const { Option } = Select

interface AudioSidebarProps {
  store: any
}


export const BackgroundMusicComp = ({ store }: AudioSidebarProps) => {
  const {
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    AudioFade:[audioFade, setAudioFade],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  const [selectedIndustryName, setSelectedIndustryName] = useState(`${scriptDetails?.backgroundMusic?.industry}`)
  const [selectedMusicUrl, setSelectedMusicUrl] = useState(`${scriptDetails?.backgroundMusic?.url}`)
  const [selectedMusicName, setSelectedMusicName] = useState(`${scriptDetails?.backgroundMusic?.name}`)
  const [playingUrl] = useState('')
  const [showVoiceOver, setShowVoiceOver] = useState(false)
  const [selectedVoiceName, setSelectedVoiceName] = useState(`${scriptDetails.data[0].name}`)
  const [backgroundVolume, setBackgroundVolume] = useState(
    (scriptDetails?.backgroundMusic?.backgroundVolume ?? 0.5) * 100
  )
  const [aiVoiceVolume, setAiVoiceVolume] = useState((scriptDetails?.aiVoiceVolume ?? 1) * 100)

  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}%`

  // Ref to the audio player
  // const audioRef = React.createRef<HTMLAudioElement>()
  const audioRef = useRef<HTMLAudioElement>(null)

  const [currentPlayingTrack, setCurrentPlayingTrack] = useState<string | null>(null)

  const handlePlayAudio = (previewUrl: string) => {
    if (audioRef.current) {
      audioRef.current.src = previewUrl

      audioRef.current.addEventListener('loadedmetadata', () => {
        // Log duration
        console.log('Duration:', formatDuration(Math.floor(audioRef?.current?.duration || 1)))
      })
      audioRef.current.play().catch((e) => console.error('Playback was interrupted:', e))
      setCurrentPlayingTrack(previewUrl)
    }
  }

  // Pause audio handler
  const handlePauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause()
      setCurrentPlayingTrack(null)
    }
  }

  const togglePlayPause = (value: string) => {
    const [industry, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    } else {
      handlePlayAudio(musicUrl)
    }
  }

  const onVoiceOverVolumeChange = async (newValue: number) => {
    setAiVoiceVolume(newValue)
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, aiVoiceVolume: newValue / 100 }
    })
  }

  const onBackgroundVolumeChange = async (newValue: number) => {
    setBackgroundVolume(newValue as number)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript: any = { ...prevScript }

      return {
        ...updatedScript,
        backgroundMusic: { ...updatedScript.backgroundMusic, backgroundVolume: newValue / 100 },
      }
    })
  }
  const handleFadeInOut = (checked:any, event:any) => {
    setAudioFade(checked)
  }
  const handleBackgroundMusicChange = async (value: string) => {
    const [industryName, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    }

    setSelectedIndustryName(industryName)
    setSelectedMusicUrl(musicUrl)
    setSelectedMusicName(name)

    const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    // setBackgroundMusic({ url: musicUrl, duration: backgroundAudioDuration, industry: industryName, name })

    const backgroundScript = {
      url: musicUrl,
      duration: backgroundAudioDuration,
      industry: industryName,
      name: name,
      backgroundVolume: backgroundVolume / 100,
    }

    // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    setBackgroundMusic(backgroundScript)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, backgroundMusic: backgroundScript }
    })
  }

  const playVoiceSample = async (
    text: string,
    aiVoiceName: string,
    isElevenApi: boolean,
    elevenlabsVoiceId: string | undefined,
    index: number
  ): Promise<any> => {
    
    try {
      
      videoStyle !== 'Burst' && store.selectPage(store.pages[index].id)
      let domainId
      let token

      if (IS_FREE_PLATFORM === 'false') {
        domainId = getDomainID()
        token = getToken()
      }
      const Prevnext=getPrevNextText(scriptDetails.data, index)
      const audioUrl = await aiTextToSpeech(text, elevenlabsVoiceId, domainId, aiVoiceName, isElevenApi, token, Prevnext.prevText, Prevnext.nextText)
      const duration = await getAudioDurationInSeconds(audioUrl) // Assuming `src` is the URL to your audio file

      let newDuration
      if (videoStyle !== 'Burst') {
        newDuration =
          duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500

        if (index === store.pages.length - 1) {
          newDuration = newDuration + 1000
        }

        store.activePage.set({ duration: newDuration })
      }

      let captionsResult: any

      try {
        captionsResult = await generateCaptions(audioUrl, userDetails?.purpose?.language || '')
      } catch (e) {
        message.info('Could not generate captions for the audio')
      }

      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[index] = {
          ...updatedData[index],
          name: aiVoiceName,
          elevenLabsAiVoiceId: elevenlabsVoiceId,
          url: audioUrl,
          isElevenApi: isElevenApi,
          // aiVoiceVolume: voiceOverVolume / 100,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: text, words: captionsResult?.data?.words }],
        }

        return { ...updatedScript, data: updatedData }
      })

      if (videoStyle !== 'Burst') {
        store.pages[index].set({
          duration: newDuration,
          custom: {
            ...store.pages[index].custom,
            // aiVoiceVolume: voiceOverVolume / 100,
            sounds: [{ url: audioUrl, audioDuration: duration }],
            captions: [{ text: text, words: captionsResult?.data?.words }],
          },
        })
      }

    

      await prefetchAsset(audioUrl)
      setSelectedVoiceName(aiVoiceName)

    } catch (error) {
      console.error('Error making text-to-speech request:', error)
    
    }
  }


  const categories = Object.keys(categorisedMusicList)
  const options: any = {}

  categories.map((category) => {
    const musicList = categorisedMusicList[category][60]['PAS']
    musicList.map((musicUrl: any) => {
      const musicKey = `${category}@@@${musicUrl.url}@@@${musicUrl.name}`
      const option = {
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flex: 1 }}>{musicUrl.name}</div>
            <div style={{ flex: '0 0 auto', marginLeft: '10px', marginRight: '2px' }}>
              {' '}
              {/* Added marginLeft */}
              {musicUrl.duration}
            </div>
            <Button
              type='text'
              icon={currentPlayingTrack === musicUrl?.url ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
              onClick={(e) => {
                e.stopPropagation()
                togglePlayPause(musicKey)
              }}
            />
          </div>
        ),
        value: musicKey,
      }
      if (options[category]) {
        options[category].push(option)
      } else {
        options[category] = [option]
      }
    })
  })
  const optionValues = Object.keys(options).map((option: any) => {
    return { label: option, options: options[option] }
  })

  return (
    <>
      <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Background Music
        </h3>
        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleBackgroundMusicChange}
          value={selectedMusicName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
            </div>
          )}
          options={optionValues}
        />
        {/* <p
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginTop: '10px',
          }}
        >
          Background Music Volume
        </p>
        <Slider
          style={{
            width: '80%',
          }}
          min={1}
          max={100}
          onChange={onBackgroundVolumeChange}
          tooltip={{ formatter }}
          defaultValue={backgroundVolume}
          value={typeof backgroundVolume === 'number' ? backgroundVolume : 0}
        /> */}
      </Row>
    </>
  )
}
