import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { useGlobalContext } from '../../../../../src/context/globalContext'
import { handleCopyPage, handleDeletePage } from '../../../../../src/shared/utils/polotno/pageOperations'
import { Col } from 'antd'

interface PageOperationsRowProps {
  store: any
  index: any
}

export const PageOperationsRow = ({ store, index }: PageOperationsRowProps) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    VideoStyle: [videoStyle],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
  } = useGlobalContext()

  return (
    <Col
      span={24}
      style={{
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px 10px',
          background: 'var(--color-primary-500)',
          borderRadius: '20px',
        }}
      >
        <CopyOutlined
          onClick={() => {
            handleCopyPage(
              store,
              index,
              scriptDetails,
              setScriptDetails,
              setUndoStack,
              setRedoStack,
              videoStyle,
              isStoreUpdated,
              setIsStoreUpdated
            )
          }}
          style={{ color: 'white', fontSize: '17px' }}
          className='icon-hover-effect'
        />
        <DeleteOutlined
          onClick={() => {
            handleDeletePage(
              store,
              index,
              scriptDetails,
              setScriptDetails,
              setUndoStack,
              videoStyle,
              isStoreUpdated,
              setIsStoreUpdated,
              false
            )
          }}
          style={{ color: 'white', fontSize: '17px', marginLeft: '12px' }}
          className='icon-hover-effect'
        />
      </div>
    </Col>
  )
}
