import {
  AudioOutlined,
  BulbOutlined,
  ClockCircleOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  PictureOutlined
} from '@ant-design/icons'
import { Row } from 'antd'
import { useState, useEffect, useRef } from 'react'
import { TextPanel } from '../polotno/component/polotnoTextPanel'
import { TransitionRow } from '../video/customVideoTimeline/cardRows/transitionRow'
import { AudioSidebar } from './audioPanel'
import { CaptionsPanel } from './captions/captionsPanel'
import './rightSidebar.less'
import { ElementsPanel } from '../polotno/component/customPolotno/customElementsPanel'
import { useGlobalContext } from '../../context/globalContext'
import { ThumbnailPanel } from '../video/customVideoTimeline/cardRows/thumbnailPanel'

interface RightSidebarProps {
  store: any
}

export const RightSidebar = ({ store }: RightSidebarProps) => {

  const {
    RightSideBar: [activeComponent, setActiveComponent]
  } = useGlobalContext()
  // const [activeComponent, setActiveComponent] = useState('')
  // const [isVisible, setIsVisible] = useState(false);
  const handleToggleComponent = (componentName: string) => {
    if(activeComponent===componentName){
      setActiveComponent('')
    }else{
      setActiveComponent(componentName)
    }
  
  }


  const renderActiveComponent = () => {
    const baseStyle: React.CSSProperties = {
      width: '300px',
      height: '100%',
      overflow: 'auto',
      backgroundColor: 'var(--color-primary-50)',
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '20px 0 0 20px',
      position: 'absolute',
      top: 0,
      right: 100,
    }
    // setIsVisible(true)
    switch (activeComponent) {
      case 'text':
        return (
          <div style={baseStyle}>
            <TextPanel store={store} />
          </div>
        )
      case 'audio':
        return (
          <div style={baseStyle}>
            <AudioSidebar store={store} />
          </div>
        )
      case 'duration':
        return (
          <div style={baseStyle}>
            <TransitionRow pageID={store.activePage.id} />
          </div>
        )
      case 'element':
        return (
          <div style={baseStyle}>
            <ElementsPanel store={store} />
          </div>
        )
      case 'captions':
        return (
          <div style={baseStyle}>
            <CaptionsPanel store={store} />
          </div>
        )
        case 'thumbnail':
        return (
          <div style={baseStyle}>
            <ThumbnailPanel store={store} />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Row style={{ flexDirection: 'column', alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
        <div
          onClick={() => handleToggleComponent('text')}
          className={`sidebar-panel-tab${activeComponent === 'text' ? ' active' : ''}`}
        >
          <FontSizeOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text' style={{ alignSelf: 'center' }}>
            Edit Text
          </h3>
        </div>
        <div
          onClick={() => handleToggleComponent('element')}
          className={`sidebar-panel-tab${activeComponent === 'element' ? ' active' : ''}`}
        >
          <BulbOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Add Element</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('audio')}
          className={`sidebar-panel-tab${activeComponent === 'audio' ? ' active' : ''}`}
        >
          <AudioOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Change Audio</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('captions')}
          className={`sidebar-panel-tab${activeComponent === 'captions' ? ' active' : ''}`}
        >
          <HighlightOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Edit Captions</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('duration')}
          className={`sidebar-panel-tab${activeComponent === 'duration' ? ' active' : ''}`}
        >
          <ClockCircleOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Edit Transition</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('thumbnail')}
          className={`sidebar-panel-tab${activeComponent === 'thumbnail' ? ' active' : ''}`}
        >
          <PictureOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Edit Thumbnail</h3>
        </div>
      </Row>
      {renderActiveComponent()}
    </>
  )
}
