import { Row } from 'antd'
import React from 'react'
import { VisualOverlayRow } from './cardRows/visualOverlayRow'
import './videoTimelineCard.less'

interface VideoTimelineCardProps {
  pageID: string
  store: any
  index: number
  page: any
}

export const VideoTimelineCard: React.FC<VideoTimelineCardProps> = ({ pageID, index, store, page }) => {
  // handle clicks
  const handleCardClick = () => {
    try {
      store.selectPage(store.pages[index].id)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div
        className='video-timeline-card'
        onClick={handleCardClick}
        style={{
          backgroundColor: 'transparent', // Center the background image
          cursor: 'default',
          marginTop: '10px',
          padding: '20px',
        }}
      >
        <Row gutter={[0, 8]} style={{ flexDirection: 'column' }}>
          <VisualOverlayRow store={store} pageID={pageID} index={index} page={page} />
        </Row>
      </div>
    </>
  )
}
