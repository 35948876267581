/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, createContext } from 'react'

export type MenuKey = string

type TransitionAnimationType = {
  animation: any
  duration: number
}

export type UndoRedoState = {
  redo: any[]
  undo: any[]
}

export type GlobalContent = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UserDetail: [any, (val: any) => void]
  UpdatedTemplate: [any, (val: any) => void]
  UpdatedHeadings: [any, (val: any) => void]
  AiVoiceForVideo: [any, (val: any) => void]
  TransitionAnimation: [TransitionAnimationType[], (val: TransitionAnimationType[]) => void]
  BackgroundMusic: [any, (val: any) => void]
  ScriptDetails: [any, (val: any) => void]
  RegeneratedScriptDetails: [any, (val: any) => void]
  IsLoading: [boolean, (val: boolean) => void]
  VideoStyle: [string, (val: string) => void]
  ScriptHistory: [UndoRedoState, (val: UndoRedoState) => void]
  UndoStack: [any[], (val: any[]) => void]
  RedoStack: [any[], (val: any[]) => void]
  IsStoreUpdated: [boolean, (val: boolean) => void]
  VideoMediaType: [string, (val: string) => void]
  ProgressPercent: [any, (val: any) => void]
  AlternateScripts: [any, (val: any) => void]
  AudioFade: [boolean, (val: boolean) => void]
  Story:[string, (val: string) => void]
  ShowTextOverlay:[boolean, (val: boolean) => void]
  RightSideBar:[string, (val: string) => void]
  CurrentUserData: [any, (val: any) => void]
  RemainingTasks: [any[], (val: any[]) => void]
  PreviewLoader: [boolean, (val: boolean) => void]
  // StoryChange: [boolean, (val: boolean) => void]
}

export const _GlobalContext = createContext<GlobalContent>({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UserDetail: [{}, (val: any) => val],
  UpdatedTemplate: [{}, (val: any) => val],
  UpdatedHeadings: [{ heading: '', subHeading: '', thirdHeading: '' }, (val: any) => val],
  AiVoiceForVideo: [{}, (val: any) => val],
  TransitionAnimation: [[], (val: any) => val],
  BackgroundMusic: [{ url: '' }, (val: any) => val],
  ScriptDetails: [{}, (val: any) => val],
  RegeneratedScriptDetails: [{}, (val: any) => val],
  IsLoading: [true, (val: boolean) => val],
  VideoStyle: ['', (val: string) => val],
  ScriptHistory: [{ redo: [], undo: [] }, (val: UndoRedoState) => val],
  UndoStack: [[], (val: any[]) => val],
  RedoStack: [[], (val: any[]) => val],
  IsStoreUpdated: [true, (val: boolean) => val],
  VideoMediaType: ['', (val: string) => val],
  ProgressPercent:[0, (val: any) => val],
  AlternateScripts:[[], (val: any) => val],
  AudioFade: [true, (val: boolean) => val],
  Story: ['', (val: string) => val],
  ShowTextOverlay: [true, (val: boolean) => val],
  RightSideBar: ['', (val: string) => val],
  CurrentUserData: [{}, (val: any) => val],
  RemainingTasks: [[], (val: any[]) => val],
  PreviewLoader: [false, (val: boolean) => val],
})

export const useGlobalContext = () => {
  return React.useContext(_GlobalContext)
}

interface StateContextProps {
  children: React.ReactNode
}

const GlobalContext = (props: StateContextProps) => {
  const { children } = props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userDetail, setUserDetail] = useState<any>()
  const [updatedTemplate, setUpdatedTemplate] = useState<any>()
  const [updatedHeadings, setUpdatedHeadings] = useState<any>({ heading: '', subHeading: '', thirdHeading: '' })
  const [aiVoiceForVideo, setAiVoiceForVideo] = useState<any[]>([])
  const [transitionAnimation, setTransitionAnimation] = useState<TransitionAnimationType[]>([
    { animation: 'fade', duration: 30 },
  ])
  const [backgroundMusic, setBackgroundMusic] = useState<any>({
    url: 'https://www.typeframes.com/audio/dont-blink.mp3',
    duration: 52.009796,
  })
  const [scriptDetails, setScriptDetails] = useState<any>()
  const [regeneratedScriptDetails, setRegeneratedScriptDetails] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [videoStyle, setVideoStyle] = useState<string>('')
  const [scriptHistory, setScriptHistory] = useState<UndoRedoState>({ redo: [], undo: [] })
  const [undoStack, setUndoStack] = useState<any[]>([])
  const [redoStack, setRedoStack] = useState<any[]>([])
  const [isStoreUpdated, setIsStoreUpdated] = useState(true)
  const [videoMediaType, setVideoMediaType] = useState<string>('Video')
  const [progressBarPercent, setProgressBarPercent]=useState<number>(0)
  const [alternateNewScripts, setAlternatNewScripts]=useState<any>([])
  const [audioFade, setAudioFade] = useState(false)
  const [editedStory, setEditedStory] = useState('')
  const [showTextOverlay, setShowTextOverlay] = useState(false)
  const [activeComponent, setActiveComponent] = useState<string>('')
  const [currentUserData, setCurrentUserData] =  useState<any>()
  const [remainingTasks, setRemainingTasks] = useState<string[]>([])
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)
  // const [storyChanged, setStoryChanged] = useState(false)
  const state: GlobalContent = {
    UserDetail: [userDetail, setUserDetail],
    UpdatedTemplate: [updatedTemplate, setUpdatedTemplate],
    UpdatedHeadings: [updatedHeadings, setUpdatedHeadings],
    AiVoiceForVideo: [aiVoiceForVideo, setAiVoiceForVideo],
    TransitionAnimation: [transitionAnimation, setTransitionAnimation],
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    ScriptDetails: [scriptDetails, setScriptDetails],
    RegeneratedScriptDetails: [regeneratedScriptDetails, setRegeneratedScriptDetails],
    IsLoading: [isLoading, setIsLoading],
    VideoStyle: [videoStyle, setVideoStyle],
    ScriptHistory: [scriptHistory, setScriptHistory],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
    VideoMediaType: [videoMediaType, setVideoMediaType],
    ProgressPercent:[progressBarPercent, setProgressBarPercent],
    AlternateScripts:[alternateNewScripts, setAlternatNewScripts],
    AudioFade:[audioFade, setAudioFade],
    Story:[editedStory, setEditedStory],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    RightSideBar: [activeComponent, setActiveComponent],
    CurrentUserData: [currentUserData, setCurrentUserData],
    RemainingTasks: [remainingTasks, setRemainingTasks],
    PreviewLoader: [isPreviewLoading, setIsPreviewLoading]
    // StoryChange:[storyChanged, setStoryChanged]
  }

  return <_GlobalContext.Provider value={state}>{children}</_GlobalContext.Provider>
}

export default GlobalContext
