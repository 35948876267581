import { FONT_TEMPLATE } from '../../../../data/captions'
import { defaultJsonTemplate } from '../../../../data/defaultJsonTemplate'
import { prefetch } from 'remotion'
import { IS_FREE_PLATFORM } from '../../../../config'
import { categorisedMusicList, categorisedMusicListTwo } from '../../../../data/music'
import { generateUniqueId } from '../../core'
import { aiTextToSoundEffect, generateAiAudio } from '.././audioGeneration'
import addLogo from '.././helperFunctions/addLogo'
import { logo, logoCTA } from '../../../../data/logo'
import { animationFunc, animationFuncCustomeOne, animationFuncCustomeOne3D } from '.././helperFunctions/animations'
import { aiImagesAPI, generateDalleImage, getGettyImages, getGettyVideos, getGettyVideosTest, getPexelsVideos } from '.././helperFunctions/mediaSearch'
import { generateSubtitles } from '../../captions'
import { getImageSize } from 'polotno/utils/image'
import { useGlobalContext } from '../../../../context/globalContext'
import { fitTextwithBreaks } from '../../polotno/resizing'
import { icons } from 'antd/es/image/PreviewGroup'

export async function ScriptToCanvasBurstRegen(
  store: any,
  scriptData: any,
  industry: any,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoStyle: string,
  videoMediaType: string,
  color:any,
  userDetails:any,
  existingMedia:any
) 
{
  // Load default Template if free platform
  // if (IS_FREE_PLATFORM === 'true') {
  store.loadJSON(defaultJsonTemplate)
  // }

  // Set default durations to 3.5 seconds
  store.pages.map((page: any) => {
    page.set({ duration: 3500 })
  })
  // if(!userDetails?.purpose?.cta){
  //   icon=""
  // }
  // Extract scriptPages from scriptData
  
  const scriptPages = scriptData.data

  let totalImageLength = 0
  // Calculate the total length of images across all objects and getting total number of pages that need to be rendered
  for (const pages of scriptPages) {
    totalImageLength += pages.images.length
  }
  // Clone the store pages based on the total image length
  for (let i = store.pages.length; i < totalImageLength; i++) {
    store.pages[i % 2].clone()
  }

  //********** Initialise loader and steps **********//
  const totalIterations = store.pages.length * 2.25 // Assuming each page has 1 iteration for video and 1 for audio generation
  const progressIncrement = 100 / totalIterations

  let currentProgress = 0

  setProgressBarPercent(Math.round(currentProgress))
  //************************************************//

  //********** Initialise scriptPages Index to parse through each section / Ad Block Type **********//
  let scriptIndex = 0
  let subIndex = 0;

  //********** Loop through all pages and generate + add audio and media **********//
  for (let index = 0; index < store.pages.length; index++) {
    //********** Generate Ai Voice Audio **********//
    const audioDuration = await generateAiAudio(
      scriptPages[scriptIndex],
      store,
      scriptIndex,
      setScriptDetails,
      setProgressBarPercent,
      currentProgress,
      progressIncrement,
      scriptPages.length,
      industry,
      videoStyle,
      userDetails,
      selectedSize,
      scriptPages
    )

    //********** Get the Section from the script **********//
    const scriptPage = scriptPages[scriptIndex]

    // ********** Calculate the duration of each Image / Page by equally dividing the audio duration by length of images inside this scriptPage ********** //
    const pageDuration = audioDuration / scriptPage.images.length

    // await aiTextToSoundEffect(scriptPages[0]?.soundEffect || 'Drum beats', 20)
    //********** Loop all images / relevant pages for each script block **********//
    for (let j = 0; j < scriptPage.images.length; j++) {
      const storeScriptIndex = scriptIndex
      const storeImageIndex = j

      // Get all children of the page
      const children = store.pages[index].children

      //
      const textElements = children.filter((child: any) => child.type === 'text')

      const sortedTextElementsByFont = textElements
        .filter(() => {
          return true
        })
        .sort((a: any, b: any) => b.fontSize - a.fontSize)
      if (sortedTextElementsByFont.length > 0) {
        const element = store.getElementById(sortedTextElementsByFont[0].id)
        const fontSize = fitTextwithBreaks(
          element.width,
          element.height,
          element.text,
          element.fontFamily,
          element.lineHeight,
          element.fontSize
        ) // Calculate the new font size
        await element.set({
          // text: scriptPages[scriptIndex].textOverlay,
          // text:'',
          fontSize: fontSize,
          y:store.height - 300,
          visible: false
        })
      }

      // Get Image object from script
      const pageImage = scriptPage.images[j]

      // Get the first search query set searchIndex for parsing later for the image
      let searchIndex = 0
      let searchQuery = pageImage.searchQueries[searchIndex]

      // Set Initial Orientation based on size input
      let orientations = selectedSize === '9:16' ? 'vertical' : 'horizontal'

      // Set Initial VideoElementID to empty string
      let mediaElementID = ''

      // Set passed to false to determine if all search queries are exhausted on getty and we need to move to Pexels
      let passed = false

      // Find the largest mediaEkement
      const videoElements = children.filter((child: any) => child.type === 'image')
      if (videoElements.length > 0) {
        const largestVideo = videoElements.reduce(
          (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
          videoElements[0]
        )
        mediaElementID = largestVideo.id
      }

      // Initialize undefined search result for media
      let searchResult = undefined

      // Only replace media if we have an mediaElementID
      if (mediaElementID !== '') {
        // Run logic to Replace with videos if it is not the free platform
        if (IS_FREE_PLATFORM === 'false' && videoMediaType === 'Videos') {
          // While loop to search for videos on Getty and Pexels for all search queries until we find one result
          while (!searchResult && searchIndex < pageImage.searchQueries.length) {
            // If all search queries are exhausted and we still have no search Result, change the orientation and try on Getty again
            if (searchIndex === pageImage.searchQueries.length - 1) {
              searchIndex = 0
              orientations = orientations === 'vertical' ? 'horizontal' : 'vertical'
              // If Getty is exhausted, switch to Pexels
              if (passed) {
                let pexelsVideoURL = undefined
                // While loop to search for videos on Pexels for all search queries until we find one result
                while (!pexelsVideoURL && searchIndex < pageImage.searchQueries.length) {
                  const pexelsVideoRes = await getPexelsVideos(pageImage.searchQueries[0])
                  if (pexelsVideoRes != -1) {
                    const videoId = generateUniqueId()

                    pexelsVideoURL = pexelsVideoRes

                    // if(userDetails?.purpose?.cta && store.pages.length-1 === index){
              
                    //   store.pages[index].set({
                    //     background: color,
                    //     // width: store.width,
                    //     // height: store.height,
                    //   })
                    //   store.pages[index].addElement({
                    //     type: 'figure',
                    //     fill: 'rgba(0,0,0,0.22)',
                    //     // width: store.width,
                    //     // height: store.height,
                    //     id: videoId,
                    //   })
                    // }
                    // else{
                      store.pages[index].addElement({
                        type: 'video',
                        src: pexelsVideoURL,
                        x: -25,
                        y: -25,
                        width: store.width + 50,
                        height: store.height + 50,
                        id: videoId,
                        custom:{voiceOver:scriptPages[scriptIndex].voiceOver, subIndex:subIndex}

                      })
                    // }
                   
                    // Delete the older element
                    store.deleteElements([mediaElementID])
                    store.getElementById(videoId).set({
                      animations: animationFuncCustomeOne(pageDuration),
                    })
                    store.getElementById(videoId).moveBottom()
                    subIndex++
                    break
                  }
                  searchIndex++
                  searchQuery = pageImage.searchQueries[searchIndex]
                }
                break
              }
              passed = true
            }

            // Search for videos on Getty
            const searchArrayindex = Math.floor(Math.random() * pageImage.searchQueries.length);
            searchQuery=pageImage.searchQueries[searchArrayindex]
            const gettyVideoURL: any = await getGettyVideosTest(searchQuery, orientations)
            if (gettyVideoURL === -1) {
              searchIndex++
              searchQuery = pageImage.searchQueries[searchIndex]
            } else {
              const videoId = generateUniqueId()
              searchResult = gettyVideoURL
              // if(userDetails?.purpose?.cta && store.pages.length-1 === index){
              
                // store.pages[index].set({
                //   background: color,
                //   width: selectedSize === "9:16" ? store.width: 2000,
                //   height: selectedSize === "9:16" ? store.height: 1125,
                // })
                // store.pages[index].addElement({
                //   type: 'figure',
                //   fill: 'rgba(0,0,0,0.22)',
                //   width: selectedSize === "9:16" ? store.width: 2000,
                //   height: selectedSize === "9:16" ? store.height: 1125,
                //   id: videoId,
                // })
              // }
              // else{
                store.pages[index].addElement({
                  type: 'video',
                  src: gettyVideoURL.uri,
                  x: -25,
                  y: -25,
                  width: store.width + 50,
                  height: store.height + 50,
                  id: videoId,
                  custom:{gettyId:gettyVideoURL.id, voiceOver:scriptPages[scriptIndex].voiceOver, subIndex:subIndex}

                  // animations:animationFunc(),
                })
              // }
              // store.pages[index].addElement({
              //   type: 'video',
              //   src: gettyVideoURL,
              //   x: -25,
              //   y: -25,
              //   width: store.width + 50,
              //   height: store.height + 50,
              //   id: videoId,
              //   // animations:animationFunc(),
              // })
              // Delete the older element
              store.deleteElements([mediaElementID])
              store.getElementById(videoId).set({
                animations: animationFuncCustomeOne(pageDuration),
              })
              store.getElementById(videoId).moveBottom()
              subIndex++
              break
            }
          }
        } else {
          // If free platform, replace with images with the similar logic of video replacement
         
          while (!searchResult && searchIndex < pageImage.searchQueries.length) {
            if(videoMediaType === 'AiImages' || videoMediaType ==='Dalle'){
              const randomIndex = Math.floor(Math.random() * pageImage.searchQueries.length) || 0
              let aiImageUrl:any
              switch(videoMediaType){
                case 'AiImages':
                  aiImageUrl = await aiImagesAPI(pageImage.searchQueries[randomIndex], orientations, userDetails?.purpose?.aiImagePrefix || '')
                  break
                case 'Dalle':
                  aiImageUrl = await generateDalleImage(pageImage.searchQueries[randomIndex], orientations)
                  break
              }
             
              if(aiImageUrl === -1 || aiImageUrl === undefined){
                // searchIndex++
                searchQuery = pageImage.searchQueries[randomIndex]
              }else{
              
                const imageId = generateUniqueId()
                // console.log(aiImageUrl)
                searchResult = aiImageUrl
                  store.pages[index].addElement({
                    type: 'image',
                    src: aiImageUrl,
                    x: -25,
                    y: -25,
                    width: store.width + 50,
                    height: store.height + 50,
                    id: imageId,
                    custom:{voiceOver:scriptPages[scriptIndex].voiceOver, subIndex:subIndex}

                  })
                // }
                store.deleteElements([mediaElementID])
                store.getElementById(imageId).set({
                  animations: animationFuncCustomeOne3D(pageDuration),
                })
                store.getElementById(imageId).moveBottom()
                subIndex++

              }
            }else{
              const gettyImageUrl: any = await getGettyImages(searchQuery, orientations)
              if (gettyImageUrl === -1) {
                searchIndex++
                const searchArrayindex = Math.floor(Math.random() * pageImage.searchQueries.length);
                searchQuery=pageImage.searchQueries[searchArrayindex]
                // searchQuery = pageImage.searchQueries[searchIndex]
              } else {
                const imageId = generateUniqueId()
                searchResult = gettyImageUrl
             
              //  console.log(scriptIndex)
           
                    store.pages[index].addElement({
                      type: existingMedia.find((obj:any) => obj.burstVoiceOver === scriptPages[scriptIndex].voiceOver && obj.subIndex == subIndex)?.type || 'image',
                      src: existingMedia.find((obj:any) => obj.burstVoiceOver === scriptPages[scriptIndex].voiceOver && obj.subIndex == subIndex)?.src || gettyImageUrl,
                      x: -25,
                      y: -25,
                      width: store.width + 50,
                      height: store.height + 50,
                      id: imageId,
                      custom:{voiceOver:scriptPages[scriptIndex].voiceOver, subIndex:subIndex}

                      // animations:animationFunc(),
                    })
                // Delete the older element
                store.deleteElements([mediaElementID])
                store.getElementById(imageId).set({
                  animations: animationFuncCustomeOne(pageDuration),
                })
                store.getElementById(imageId).moveBottom()
                subIndex++

              }
            }
            
          }
        }

        // ********** Set all the relevant / needed metadata ********** //
        store.pages[index].set({
          duration: pageDuration,
          custom: {
            ...store.pages[index].custom,
            searchQuery: searchQuery,
            searchQueries: pageImage.searchQueries,
            imageIndex: storeImageIndex,
            scriptIndex: storeScriptIndex,
          },
        })
      }

      currentProgress += progressIncrement
      setProgressBarPercent(Math.round(currentProgress))

      currentProgress += progressIncrement
      setProgressBarPercent(Math.round(currentProgress))

      // Add logo if not free platform
      // https://adgen-statics.s3.ap-south-1.amazonaws.com/adgen-fe/new_logo.png
      if (IS_FREE_PLATFORM === 'false') {
        const { waitUntilDone } = prefetch(icon, {
          method: 'blob-url',
          contentType: 'image/png',
        })
        await waitUntilDone()
    if(userDetails?.purpose?.cta){
    if(store.pages.length-1 === index){
    let { width, height } = await getImageSize(icon)
    const scaleFactor = selectedSize === '9:16' ? 0.4 : 0.2
    let scale = Math.min(store.width / width, store.height / height, scaleFactor)

    // Limit width to 250 pixels
    if (width * scale > 100) {
      scale *= 100 / (width * scale) // Adjust scale to fit within 250 width
    }
    // Ensure height is scaled proportionally
    height *= scale
    width *= scale
    store.pages[index].addElement({
      ...logoCTA,
      id: 'cta' + index.toString(),
      src: `${icon}`,
      // visible: false,
     x: selectedSize === '9:16'?store.width - width - 150:store.width - width - 185,
    y: store.height - 400,
    width: selectedSize === '16:9'? width+80 : width,
    height: selectedSize === '16:9'? height+50 : height,
    opacity:userDetails?.purpose?.showLogo ? 1 : 0,
    visible:userDetails?.purpose?.showLogo
    })
  }else{
      if (icon) await addLogo(icon, index, selectedSize, store, userDetails?.purpose?.showLogo)
  }
}
      }

      //********* Move onto the next page manually so we can loop through all images inside one script block *********//
      if (j !== scriptPage.images.length - 1) {
        index++;
    }
    
    if (j === scriptPage.images.length - 1 && scriptIndex < scriptPages.length - 1) {
        scriptIndex++;
        subIndex = 0;
    }
    }
  }

  const duration = 60
  currentProgress += progressIncrement
  const thumbnailImagePrompt = scriptPages[0]?.images[0]?.searchQueries[0] || 'Random Images' +' '+ scriptPages[0]?.images[0]?.searchQueries[1] || 'Random Images' +' '+  scriptPages[0]?.images[0]?.searchQueries[2] || 'Random Images' 
  const thumbnailImage = await generateDalleImage(thumbnailImagePrompt, selectedSize === '9:16' ? 'vertical' : 'horizontal')
  setProgressBarPercent(Math.round(currentProgress))
  let musicUrl = ''
  let musicName = ''
  const style = localStorage.getItem('style') || 'Simple';
  const Prompt= localStorage.getItem('Prompt')

  try{
    if (Prompt === 'false'){
      if(categorisedMusicListTwo[style]){
        const musicArrLength = categorisedMusicListTwo[style][selectedSize].length
        const randomIndex=Math.floor(Math.random() * musicArrLength)
        musicUrl = categorisedMusicListTwo[style][selectedSize][randomIndex].url
        musicName = categorisedMusicListTwo[style][selectedSize][randomIndex].name
      }
    } else{
      if(categorisedMusicListTwo["Prompt"]){
        const musicArrLength = categorisedMusicListTwo["Prompt"][selectedSize].length
        const randomIndex=Math.floor(Math.random() * musicArrLength)
        musicUrl = categorisedMusicListTwo["Prompt"][selectedSize][randomIndex].url
        musicName = categorisedMusicListTwo["Prompt"][selectedSize][randomIndex].name
      }
    }
  }catch{
    const musicArrLength = categorisedMusicListTwo["Simple"][selectedSize].length
    const randomIndex=Math.floor(Math.random() * musicArrLength)
    musicUrl = categorisedMusicListTwo["Simple"][selectedSize][randomIndex].url
    musicName = categorisedMusicListTwo["Simple"][selectedSize][randomIndex].name
  }


  const backgroundScript = {
    url: musicUrl,
    duration: 30,
    industry: industry,
    name: musicName,
    backgroundVolume: 0.1,
  }

  // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
  setBackgroundMusic(backgroundScript)

  ////*********  Generate subtitles by default if free version *********//
  await generateSubtitles(
    'Poppins',
    FONT_TEMPLATE[0].wordsPerLine,
    30,
    store,
    FONT_TEMPLATE[0].wordsPerLine,
    FONT_TEMPLATE[0],
    true,
    store.height - store.height * 0.4,
    () => {
      true
    },
    setScriptDetails
  )
  
  localStorage.setItem('thumbnailImage',thumbnailImage)
  await setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript }
    return { ...updatedScript, backgroundMusic: backgroundScript, thumbnailImage:thumbnailImage,  transitionSound:false, audioFade:false, aiVoiceSpeed: 1 }
  })
  console.log(store.toJSON())
  store.selectPage(store.pages[0].id)
  store.history.clear()
}
