import React, { useEffect, useRef } from 'react'
import { AbsoluteFill, Img, OffthreadVideo, staticFile, useCurrentFrame, useVideoConfig } from 'remotion'
import { applyAnimations, calculateTrimTimes, prefetchAsset } from '../../../shared/utils/remotion'


interface Animation {
  delay: number
  duration: number
  enabled: boolean
  type: string
  name: string
  data: any
}

interface Child {
  custom: any
  id: string
  type: string
  text?: string
  animations: Animation[]
  src?: string
  x: number
  y: number
  width: number
  height: number
  rotation: number
  fontFamily?: string
  fontSize?: number
  fontWeight?: string
  fontStyle?: string
  textAlign?: string
  fill?: string
  cornerRadius?: number
  borderSize?: number
  cropX: number
  cropY: number
  blurEnabled: boolean
  blurRadius: number
  sepiaEnabled: boolean
  grayscaleEnabled: boolean
  brightnessEnabled: boolean
  brightness: number
  shadowEnabled: boolean
  shadowBlur: number
  letterSpacing: number
  strokeWidth: number
  stroke: string
  lineHeight: number
  cropWidth: number
  cropHeight: number
  duration: number
  startTime: number
  endTime: number
}

interface PageData {
  id: string
  children: Child[]
}

interface SinglePageProps {
  pageHtml: any // HTML string for the page content
  width: number
  height: number
  pageData: PageData // Adjusted to pass JSON data
  isDownload: boolean
}



export const SinglePage: React.FC<SinglePageProps> = React.memo(
  ({ pageHtml, width, height, pageData, isDownload }: SinglePageProps) => {
    const frame = useCurrentFrame()
    const { fps, durationInFrames } = useVideoConfig()
    const pageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      applyAnimations(pageRef, pageData, frame, fps, durationInFrames, width, height)

    }, [frame, pageData])

    useEffect(() => {
      async function prefetch() {
        await Promise.all(
          pageData?.children.map(async (child: any) => {
            // Prefetch for all children in parallel
            child.type === 'video' || (child.type === 'image' && child.src)
              ? prefetchAsset(child.src,  child.type === 'image' ? 'image/png' : 'video/mp4')
              : Promise.resolve()
          })
        )
      }
      prefetch()
    }, [])

    const scaleFactor = isDownload ? 1 : Math.min(width / 1 / width, height / 1 / height)
    // 3D effect variables (can be dynamic based on frame or other conditions)
    // const rotationX = (Math.sin(frame / 50) * 10); // Rotate X based on frame
    // const rotationY = (Math.cos(frame / 50) * 10); // Rotate Y based on frame
    // const translateZ = 150; // Translate the element in Z-axis
    // const perspectiveDistance = 1000; // Perspective depth

    return (
      <AbsoluteFill>
        <AbsoluteFill>
          {pageData?.children.map((child, index) => {
            if (child.type === 'video' && child.src) {
              const cropStyle: React.CSSProperties = {
                position: 'absolute',
                overflow: 'hidden',
                left: isDownload ? `${child.x}px` : `${child.x * scaleFactor}px`,
                top: isDownload ? `${child.y}px` : `${child.y * scaleFactor}px`,
                width: isDownload ? `${child.width}px` : `${child.width * scaleFactor}px`,
                height: isDownload ? `${child.height}px` : `${child.height * scaleFactor}px`,
                transform: `rotate(${child.rotation}deg)`,
              }
              // console.log(child)
              // console.log(child.duration, child.startTime, child.endTime)
              const trimedDuration = calculateTrimTimes(child.duration || child?.custom?.duration || 20000, child.startTime || child?.custom?.startTime || 0, child.endTime || child?.custom?.endTime || 1, fps)
              console.log(trimedDuration.startTime)
              return (
                <div key={child.id} style={cropStyle}>
                  <OffthreadVideo
                    src={child.src}
                    style={{
                      position: 'absolute',
                      width: '100%', // Cover the full parent area
                      height: '100%', // Adjust for cropHeight
                      objectFit: 'cover',
                      objectPosition: '100% 100%',
                    }}
                    startFrom={trimedDuration.startTime}
                    // endAt={trimedDuration.endTime}
                    muted
                  />
                </div>
              )
            } else if (child.type === 'image' && child.src) {
              const cropStyle: React.CSSProperties = {
                position: 'absolute',
                overflow: 'hidden',
                left: isDownload ? `${child.x}px` : `${child.x * scaleFactor}px`,
                top: isDownload ? `${child.y}px` : `${child.y * scaleFactor}px`,
                width: isDownload ? `${child.width}px` : `${child.width * scaleFactor}px`,
                height: isDownload ? `${child.height}px` : `${child.height * scaleFactor}px`,
                // width: `753px`,
                // height: `424px`,
                transform: `rotate(${child.rotation}deg)`,
              }

              return (
                <div key={child.id} style={cropStyle}>
                  <Img
                    src={child.src}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: `${child.cropX}% ${child.cropY}%`,
                      opacity: 0,
                    }}
                  />
                </div>
              )
            }
            return null
          })}
        </AbsoluteFill>
        <AbsoluteFill>
          <div
            ref={pageRef}
            style={{
              position: 'absolute',
  
              transform: isDownload ? 'none' : `scale(${scaleFactor})`,
              // transform: isDownload
              //   ? 'none'
              //   : `perspective(${perspectiveDistance}px) rotateX(${rotationX}deg) rotateY(${rotationY}deg) translateZ(${translateZ}px) scale(${scaleFactor})`,
              // transformOrigin: 'center center',
            
              transformOrigin: 'top left',

              width: `${width}px`,
              height: `${height}px`,
            }}
            dangerouslySetInnerHTML={{ __html: pageHtml }}
          />
        </AbsoluteFill>
       
      </AbsoluteFill>
    )
  }
)

SinglePage.displayName = 'SinglePage'
