/* eslint-disable react/prop-types */
import { LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { bultCreateArray } from '../../../data/bulkCreate'
import { burstImageTransitionFunctions } from '../../../data/transitions'
import { BulkCreateTab } from '../../../features/video/blukCreateTab/bulkCreateTab'
import { trackEvent } from '../../../lib/mixpanel'
import { resizeDesign } from '../../../shared/utils/polotno/resizing'
import { millisecondsToFrames } from '../../../shared/utils/remotion'
import { Button, Modal } from 'antd'
import { useState } from 'react'
import { useGlobalContext } from '../../../context/globalContext'
import { extractPagesFromHtml, addIdsToHtmlFromJson } from '../../../shared/utils/remotion/htmlProcessing'

// import { extractPagesFromHtml, addIdsToHtmlFromJson } from '@src/shared/utils/remotion/htmlProcessing'

interface BulkCreateButtonProps {
  store: any // Ideally, replace `any` with a more specific type
  open: boolean
  setOpen: any
  bulkCreatePropsArray: any
  setBulkCreatePropsArray: any
}

async function bulkCreateFunction(
  store: any,
  userDetails: any,
  setUserDetails: any,
  scriptDetails: any,
  videoStyle: string
) {
  let bulkCreateProps: any[] = []

  for (let i = 0; i < bultCreateArray.length; i++) {
    const value = bultCreateArray[i].value
    const selectedRatio = bultCreateArray.find((item) => item.value === value)

    if (!selectedRatio) return

    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

    await resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, value, true)

    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 0,
        })
      }
    })

    const polotnoJson = await store.toJSON()

    const polotnoHtml = await store.toHTML()

    const htmlCode = extractPagesFromHtml(polotnoHtml)

    const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, polotnoJson)

    let updatedScriptWithYposition = scriptDetails

    updatedScriptWithYposition = {
      ...updatedScriptWithYposition,
      template: {
        ...updatedScriptWithYposition.template,
        position: store.height - store.height * 0.2,
      },
    }

    const burstImagesTransitionAnimationArray: any = []

    if (videoStyle === 'Burst') {
      // const burstImagesTransitionAnimationArray = []

      for (let i = 0; i < scriptDetails.data.length; i++) {
        const innerArray = []

        for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
          const randomFunction =
            burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
          innerArray.push(randomFunction.value)
        }
        burstImagesTransitionAnimationArray.push(innerArray)
      }
    }

    bulkCreateProps = [
      ...bulkCreateProps,
      {
        id: bultCreateArray[i].id,
        htmlCode: htmlCodeWithTags,
        width: store.width,
        height: store.height,
        jsonData: polotnoJson,
        duration: millisecondsToFrames(store.duration, 30),
        burstImagesTransitionAnimationArray: burstImagesTransitionAnimationArray,
        videoStyle: videoStyle,
        scriptDetails: updatedScriptWithYposition,
      },
    ]
  }
  return bulkCreateProps
}

export const BulkCreateButton: React.FC<BulkCreateButtonProps> = ({
  store,
  open,
  setOpen,
  bulkCreatePropsArray,
  setBulkCreatePropsArray,
}) => {
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  // loading for when gif is rendering
  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)
  const User = JSON.parse(localStorage.getItem('userData') as string)

  const [aspectRatio, setAspectRatio] = useState<any>(userDetails?.purpose?.size)

  async function undo() {
    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 1,
        })
      }
    })

    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

    await resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, aspectRatio)
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setOpen(false)
    undo()
  }

  const [width, setWidth] = useState<number>(500)

  const modalTitle = (
    <div
      style={{ width: '100%', cursor: 'move' }}
      className='draggable-modal-title' // This class will be used as a handle
    >
      Multi-Platform Preview
    </div>
  )

  return (
    <>
      <Button
        onClick={async () => {
          trackEvent('Bulk Videos Generated')
          setIsGifRendering(true)
          const bulkCreatePropsResult = await bulkCreateFunction(
            store,
            userDetails,
            setUserDetails,
            scriptDetails,
            videoStyle
          )
          console.log(bulkCreatePropsResult)
          setBulkCreatePropsArray(bulkCreatePropsResult ?? [])
          showModal()
          setIsGifRendering(false)
        }}
        style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', marginBottom: '10px', color: 'var(--color-primary-500)' }}
        icon={isGifRendering ? <LoadingOutlined /> : <PlayCircleOutlined />}
      >
        Multi-Platform Preview
      </Button>
      <Modal
        title={modalTitle}
        open={open}
        onOk={handleOk}
        onCancel={handleOk}
        okText={'Close'}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
        destroyOnClose={true}
      >
        <BulkCreateTab store={store} bulkCreateArray={bulkCreatePropsArray} />
      </Modal>
    </>
  )
}
