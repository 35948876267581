/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-children-prop */
import { VideoCameraOutlined } from '@ant-design/icons'
import { useGlobalContext } from '../../../../src/context/globalContext'
import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { SectionTab } from 'polotno/side-panel'
import { useEffect, useState } from 'react'
import './videoTimeline.less'
import { VideoTimelineCard } from './videoTimelineCard'
const { Option } = Select

interface Child {
  id: string
  type: string
  text?: string
}

interface PageItem {
  id: string
  type: string
  name: string
  duration: number
  children: Child[]
}

interface JsonData {
  width: number
  height: number
  fonts: any[]
  pages: PageItem[]
}

const VideoTimelinePanel = observer(({ store }: any) => {
  const {
    UserDetail: [userDetails],
    IsLoading: [isLoading],
    IsStoreUpdated: [isStoreUpdated],
    VideoStyle: [videoStyle],
  } = useGlobalContext()

  const [pages, setPages] = useState<PageItem[] | undefined>(undefined)

  let jsonData: JsonData

  useEffect(() => {
    const updatePages = async () => {
      jsonData = await store.toJSON()
      setPages(jsonData.pages)
    }

    updatePages()
  }, [isStoreUpdated])

  return (
    <>
      <div style={{ overflowY: 'auto', overflowX: 'auto', height: 'calc(100vh - 100px)', paddingTop: '5px' }}>
        <div>
          {!isLoading && userDetails && pages && pages.length > 0 ? (
            pages.map((item, index) => (
              <VideoTimelineCard key={item.id} pageID={item.id} store={store} index={index} page={item} />
            ))
          ) : (
            <div style={{ display: 'flex', alignContent: 'center' }}>{/* <Spin /> */}</div>
          )}
        </div>
      </div>
    </>
  )
})

export const CustomVideoTimelineSection = {
  name: 'video-timeline',
  Tab: (props: any) => (
    <SectionTab name='Video Editor' {...props}>
      <VideoCameraOutlined />
    </SectionTab>
  ),
  Panel: VideoTimelinePanel,
}
