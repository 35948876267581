import { useGlobalContext } from '@src/context/globalContext';
import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import UploadImage from '../thumbnailComponent/uploadImage';

interface ThumbnailProp {
    store: any;
  }
  
export const ThumbnailPanel: React.FC<ThumbnailProp> = React.memo(
    ({ store }: ThumbnailProp) => {
        const {
            ScriptDetails: [scriptDetails, setScriptDetails],
        } = useGlobalContext()
    return(
        <>
        <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  marginRight: '10px',
                }}
              >
                <UploadImage/>
              </h3>
        </Row>
        </>
    )
})

ThumbnailPanel.displayName = 'ThumbnailPanel';
