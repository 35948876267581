import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

export function trackEvent(
  event_name: string,
  properties?: Dict | undefined,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined
) {
  try {
    mixpanel.track(event_name, properties, optionsOrCallback, callback);
  } catch (err) {
    console.log(err);
  }
}
